import React from "react"
import { graphql } from "gatsby"

import Image from "../../components/Image"
import Heading from "../../components/Heading"
import Button from "../../components/Button"

import styles from "./our-story.module.scss"
// import wideImageStyles from "../../components/wideimage.module.scss"
import oneByOneStyles from "../../components/onebyone.module.scss"
import heroStyles from "../../components/hero.module.scss"
import settings from "../../components/settings.module.scss"

const OurStory = ({ data }) => {
  // const heroImage = data.allContentfulImage.nodes[0]
  const oneByOneImage = data.allContentfulImage.nodes[1]
  const reasonImage1 = data.allContentfulImage.nodes[2]
  // const reasonImage2 = data.allContentfulImage.nodes[3]
  const reasonImage3 = data.allContentfulImage.nodes[4]

  return(
    <>
      <section className={oneByOneStyles.oneByOne}>
        <div
          className={oneByOneStyles.container}
        >
          <Image
            image={oneByOneImage}
            altText={oneByOneImage.altText}
            options={{ sizing: "one-by-one" }}
            className={oneByOneStyles.image}
          />
        </div>
        <div
          className={`${oneByOneStyles.container} ${oneByOneStyles.valuePropContainer}`}
        >
          <section className={oneByOneStyles.valueProp}>
            <p className={styles.oneByOneText}>
              “I co-founded Jimmy Choo in 1996. Today, I'm still obsessed with shoes, but not the traditional way of doing things. So I’m starting over. This is my reboot (pun intended). I'm redefining luxury and doing what I do best: designing shoes and breaking rules.”
            </p>
          </section>
        </div>
      </section>
      <section className={`${heroStyles.hero} ${settings.contentAlignRightMiddle} ${settings.colorSchemeLight} ${styles.reason}`}>
        <Image
          image={reasonImage1}
          options={{ sizing: "art-directed", alignment: "center center" }}
          className={heroStyles.imageHero}
          customStyles={{ position: "absolute" }}
        />
        <Heading
          tag="h2"
          level="1"
          className={`${heroStyles.heading1} ${settings.heading1} ${styles.heading}`}
        >
          Join Our Revolution
        </Heading>
        <p
          className={`${heroStyles.subHeading} ${settings.subHeading} ${styles.subHeading}`}
        >
          I've been around the block more than once (in killer heels). I know what women need, and what they want. My shoes are designed for women, by women. They're for leaning in, climbing ladders, and shattering ceilings.
        </p>
      </section>
      {/*<section className={`${heroStyles.hero} ${settings.contentAlignRightMiddle} ${settings.colorSchemeDark} ${styles.reason}`}>*/}
      {/*  <Image*/}
      {/*    image={reasonImage2}*/}
      {/*    options={{ sizing: "art-directed", alignment: "center center" }}*/}
      {/*    className={heroStyles.imageHero}*/}
      {/*    customStyles={{ position: "absolute" }}*/}
      {/*  />*/}
      {/*  <Heading*/}
      {/*    tag="h2"*/}
      {/*    level="1"*/}
      {/*    className={`${heroStyles.heading1} ${settings.heading1} ${styles.heading}`}*/}
      {/*  >*/}
      {/*    New Prices, <br />Best Craftsmanship*/}
      {/*  </Heading>*/}
      {/*  <p*/}
      {/*    className={`${heroStyles.subHeading} ${settings.subHeading} ${styles.subHeading}`}*/}
      {/*  >*/}
      {/*    Forget shoes priced 6x what it cost to make them. I’ve forged relationships with family-run factories in Italy that have made the best quality luxury shoes for decades. And they won’t strain your body or your bank account. No caveats or markups— just time-honored craftsmanship at direct-to-women prices.*/}
      {/*  </p>*/}
      {/*</section>*/}
      <section className={`${heroStyles.hero} ${settings.contentAlignRightMiddle} ${settings.colorSchemeLight} ${styles.reason}`}>
        <Image
          image={reasonImage3}
          options={{ sizing: "art-directed", alignment: "center center" }}
          className={heroStyles.imageHero}
          customStyles={{ position: "absolute" }}
        />
        <Heading
          tag="h2"
          level="1"
          className={`${heroStyles.heading1} ${settings.heading1} ${styles.heading}`}
        >
          Redefining the <br />Luxury Experience
        </Heading>
        <p
          className={`${heroStyles.subHeading} ${settings.subHeading} ${styles.subHeading}`}
        >
          We make shoes that age slowly and get to you quickly, and we apply our same standard of craftsmanship to our customer experience.
        </p>
        <p
          className={`${heroStyles.subHeading} ${settings.subHeading} ${styles.subHeading}`}
        >
          <Button
            color="Light"
            outline={true}
            url="/collections/all"
            type="Link"
            className={styles.button}
          >
            Shop All
          </Button>
        </p>
      </section>
    </>
  )
}

export default OurStory

export const query = graphql`
  query ourStoryQuery {
    allContentfulImage(filter: {imageName: {glob: "Our Story *"}}, sort: {fields: imageName}) {
      nodes {
        desktopImage {
          fluid(maxWidth: 2560, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        mobileImage {
          fluid(maxWidth: 768, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        altText
        url
      }
    }
  }
`
